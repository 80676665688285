import StandardBlockModel from '../../StandardBlock/Models/StandardBlockModel.interface';
import { styled } from '../../stitches.config';
import GridItem from '../../Atoms/Grids/GridItem';
import useMedia from '../../Shared/Hooks/useMedia';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import KexLink from '../../Kex/KexLink';

type PropType = {
  content: StandardBlockModel;
  blockId: any;
};

function StandardBlock({
  content: { heading, image, mobileImage, bodyText, link, layout, imageRight },
  blockId,
}: PropType) {
  const { 'common/readMore': readMoreLabel } = useTranslationData();

  const isMobile = useMedia(mediaQueryTypes.mediaMaxMedium);

  const { text: linkText, href = '', ...restLink } = link || {};

  return (
    <GridItem layout={layout}>
      <Root
        blockLayout={layout?.replace('/', '')}
        data-litium-block-id={blockId}
      >
        {image && (
          <ImageArea
            css={{
              backgroundImage:
                isMobile && mobileImage
                  ? `url(${mobileImage.src})`
                  : image && `url(${image.src})`,
            }}
            imageRight={imageRight && layout === '1/1'}
            blockLayout={layout?.replace('/', '')}
          >
            {!!href.length && <ImageLink href={href} />}
          </ImageArea>
        )}
        <TextContainer blockLayout={layout?.replace('/', '')}>
          <StyledH2>{heading}</StyledH2>
          <BodyText
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
          {link && (
            <BlockLink
              href={href}
              type={'secondary'}
              noMargin={layout === '1/1'}
              uppercase={true}
              {...restLink}
            >
              <span>{linkText ? linkText : readMoreLabel}</span>
            </BlockLink>
          )}
        </TextContainer>
      </Root>
    </GridItem>
  );
}

export default StandardBlock;

const Root = styled('div', {
  flexDirection: 'column',
  display: 'flex',
  wh: '100%',
  variants: {
    blockLayout: {
      11: {
        '@mediaMinMedium': {
          flexDirection: 'row',
        },
      },
    },
  },
});

const StyledH2 = styled(H2, {
  mt: 0,
  mb: 2,
});

const BlockLink = styled(CtaButton, {
  fs: 5,
  '@mediaMaxSmall': {
    lineHeight: '16px',
  },
  '@mediaMinSmall': {
    fs: 6,
    lineHeight: '24px',
    mt: 'auto',
  },
  variants: {
    noMargin: {
      true: {
        mt: '0',
      },
    },
    uppercase: {
      true: {
        textTransform: 'uppercase',
      },
    },
  },
});

const ImageArea = styled('div', {
  w: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  position: 'relative',
  order: 0,
  br: 2.5,
  aspectRatio: '3/2',
  h: 'auto',
  '@mediaMinSmall': {
    h: 'fit-content',
  },
  variants: {
    imageRight: {
      true: {
        '&:nth-child(n)': {
          '@mediaMinMedium': {
            order: 1,
          },
        },
      },
    },
    blockLayout: {
      11: {
        '@mediaMinMedium': {
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: '50%',
          h: 'auto',
        },
      },
    },
  },
});

const ImageLink = styled(KexLink, {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const TextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  py: 8,
  w: '100%',
  color: '$textPrimary',
  linHeight: '24px',
  '@mediaMaxSmall': {
    fs: 6,
  },
  '@mediaMinSmall': {
    flexGrow: 1,
  },
  '@mediaMinMedium': {
    p: 8,
  },
  '@mediaMaxMedium': {
    px: 3,
  },
  '@mediaMinLarge': {
    alignItems: 'center',
    textAlign: 'center',
  },
  variants: {
    whiteText: {
      true: {
        color: '$white',
      },
    },
    blockLayout: {
      13: {
        '@mediaMinLarge': {
          py: 8,
          px: 0,
        },
      },
      14: {
        '@mediaMinLarge': {
          py: 8,
          px: 0,
        },
      },
    },
  },
});

const BodyText = styled('div', {
  mb: 6,
  fontWeight: '$fw300',
  '@mediaMinLarge': {
    mb: 6,
  },
});
